import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Table1 from "../../components/Table1";
import CreateAppointmentForm from "../../components/CreateAppoinment";
import axios from "axios";
import { toast } from "react-toastify";
import { MdDelete } from "react-icons/md";
import { FaPen } from "react-icons/fa";
import GrowExample from '../../components/spiner/GrowExample';
import { Button } from "react-bootstrap";
import { useAuth } from "../../context/AuthContext";


function PreCheckup() {
  const { username } = useAuth();
  const [registrationData, setRegistrationData] = useState([]);
  const [preCheckUpData, setPreCheckUpData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [createForm, showCreateForm] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
console.log('preCheckUpData', preCheckUpData);
  const loadRegistrationData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get("https://hospital-api.hisabdev.com/api/outdoreregistation");
      const filteredData = response.data[0].filter(reg => reg.status === "not-checked");
      const filteredDataByUserName = filteredData.filter(item => item.createdBy === username)
      setRegistrationData(filteredDataByUserName);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const loadPreCheckupData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get("https://hospital-api.hisabdev.com/api/outdoor-pre-checkup");
      const filteredDataByUserName = response.data[0].filter(item => item.createdBy === username)
      setPreCheckUpData(filteredDataByUserName); // Ensure this matches your API response
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadRegistrationData();
    loadPreCheckupData();
  }, [createForm]);

//   const deleteContact = (id) => {
//     if (
//       window.confirm("Are you sure that you wanted to delete that patient?")
//     ) {
//       axios.delete(
//         `https://hospital-api.hisabdev.com/api/registation/removeregistation/${id}`
//       );
//       toast.success("Delete Successfully");
//       setTimeout(() => loadRegistrationData(), 500);
//     }                                                                                                                                                                                                                                                                        
//   };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    }).format(date);
  };

  const registrationColumns = [
    {
      header: "Date",
      accessorKey: "date",
      cell: (rowData) => formatDate(rowData.getValue()),
    },
    {
      header: "Patient Code",
      accessorKey: "orpCode",
    },
    {
      header: "Patient Name",
      accessorKey: "name",
    },
    {
      header: "Gender",
      accessorKey: "sex",
    },
    {
      header: "Mobile Number",
      accessorKey: "mobilenumber",
    },
    {
      header: "Age",
      accessorKey: "age",
    },
    {
      header: "Doctor Name",
      accessorKey: "doctorname",
    },
    {
      header: "Time",
      accessorKey: "time",
    },
    {
      header: "Address",
      accessorKey: "address",
    }
  ];

  const preCheckupColumns = [
    // Define columns for pre-checkup data
    {
      header: "Date",
      accessorKey: "date",
      cell: (rowData) => formatDate(rowData.getValue()),
    },
    {
      header: "Patient Code",
      accessorKey: "orpCode",
    },
    {
      header: "Patient Name",
      accessorKey: "name",
    },
    {
      header: "Gender",
      accessorKey: "sex",
    },
    {
      header: "Mobile Number",
      accessorKey: "mobilenumber",
    },
    {
      header: "Age",
      accessorKey: "age",
    },
    {
      header: "Doctor Name",
      accessorKey: "doctorname",
    },
    {
      header: "Address",
      accessorKey: "address",
    },
    {
      header: "Guardian Number",
      accessorKey: "guardiannumber",
    },
    {
      header: "Guardian Name",
      accessorKey: "guardianname",
    },
    {
      header: "Test",
      accessorKey: "tests",
      cell: ({ row }) => {
        const items = row.original.tests;
        return (
          <div>
            {items && items.length > 0 ? (
              items.map((item, index) => (
                <div key={index}>
                  <strong>Test Name:</strong> {item.testname}, <strong>Rate:</strong> {item.amount}
                </div>
              ))
            ) : (
              <div>No items</div>
            )}
          </div>
        );
      }
    },
    {
      header: "Amount",
      accessorKey: "amount",
    },
    {
      header: "Notes",
      accessorKey: "notes",
    },
  ];

  const handleUpdate = (id) => {
    navigate(`/pre-checkup/${id}`);
  };

  return (
    <div className="container p-2">
      <h2 className="text-xl font-bold mb-4">Pre Doctor Check-up</h2>
      {createForm ? (
        <CreateAppointmentForm
          onCancel={() => {
            showCreateForm(false);
          }}
        />
      ) : (
        <div className="flex justify-between">
          {/* <Button
            className="mb-2 drop-shadow"
            onClick={() => showCreateForm(true)}
          >
            Register New Appointment
          </Button> */}
        </div>
      )}
      {isLoading && !registrationData[0] && !preCheckUpData[0] && (
        <div className="w-full h-60 flex justify-center items-center">
          <GrowExample />
        </div>
      )}
      <div className="mb-4">
        <h3 className="text-lg font-semibold mb-2 text-red-600">Registration Data</h3>
        {registrationData.length > 0 ? (
          <div className="drop-shadow mb-4">
            <Table1
              data={registrationData}
              columns={[
                {
                  header: "No.",
                  cell: (row) => <>{row.cell.row.index + 1}</>,
                },
                ...registrationColumns,
                {
                  header: "Actions",
                  cell: (rowData) => (
                    <div className="flex justify-between">
                      <button
                        onClick={() => handleUpdate(rowData.row.original.id)}
                        className="btn btn-primary btn-sm me-2"
                      >
                        <FaPen />
                      </button>
                      {/* <button
                        onClick={() => deleteContact(rowData.row.original.id)}
                        className="btn btn-danger btn-sm"
                      >
                        <MdDelete />
                      </button> */}
                    </div>
                  ),
                },
              ]}
              pageLimit={10}
            />
          </div>
        ) : !isLoading ? (
          <p className="text-red-400 font-semibold w-max mx-auto my-20">
            No Registration Data
          </p>
        ) : null}
      </div>
      <div>
        <h3 className="text-lg font-semibold mb-2 text-green-900">Pre-Checkup Data</h3>
        {preCheckUpData.length > 0 ? (
          <div className="drop-shadow">
            <Table1
              data={preCheckUpData}
              columns={[
                {
                  header: "No.",
                  cell: (row) => <>{row.cell.row.index + 1}</>,
                },
                ...preCheckupColumns,
                // {
                //   header: "Actions",
                //   cell: (rowData) => (
                //     <div className="flex justify-between">
                //       {/* <button
                //         onClick={() => handleUpdate(rowData.row.original.id)}
                //         className="btn btn-primary btn-sm me-2"
                //       >
                //         <FaPen />
                //       </button>
                //       <button
                //         onClick={() => deleteContact(rowData.row.original.id)}
                //         className="btn btn-danger btn-sm"
                //       >
                //         <MdDelete />
                //       </button> */}
                //     </div>
                //   ),
                // },
              ]}
              pageLimit={10}
            />
          </div>
        ) : !isLoading ? (
          <p className="text-red-400 font-semibold w-max mx-auto my-20">
            No Pre-Checkup Data
          </p>
        ) : null}
      </div>
    </div>
  );
}

export default PreCheckup;
