import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate, useParams } from "react-router-dom";
import { Input } from "@mui/joy";
import { RxCross2 } from "react-icons/rx";
// import DrawingCanvas from "../../components/canva/DrawingCanvas";

const initialState = {
  date: new Date().toISOString().split("T")[0],
  name: "",
  mobilenumber: "",
  sex: "",
  age: "",
  notes: "",
  note: "",
  tests: [{ testname: "", result: "", amount: 0 }],
  group_tests: [{ gptestname: "", gpresult: "", gpamount: 0 }],
  medicines: [{ name: "", instruction: "", dosage: "" }],
  selectedPackage: "null",
  packageAmount: 0.0,
  discount: 0.0,
  netAmount: 0.0,
  doctorname: "",
  guardianname: "",
  guardiannumber: "",
  address: "",
  price: "0.00",
  orpCode: "",
  type: "",
  operation:""
};

const initialStatae = {
  date: "",
  address: "",
  name: "",
  image: "",
  mobilenumber: "",
  sex: "",
  age: "",
  guardiannumber: "",
  guardianname: "",
  doctorname: "",
  doctordesignation: "",
  time: "",
  type: "",
  price: "",
  notes: "",
  doctorname: "",
  guardianname: "",
  guardiannumber: "",
  address: "",
  price: "0.00",
  orpCode: "",
  type: "",
};

function Prescription({ onCreate, onCancel }) {
  const [state, setState] = useState(initialState);
  const [pstate, setPState] = useState(initialStatae);
  const [error, setError] = useState();
  const [testData, setTestData] = useState([]);
  const [GroupTestData, setGroupTestData] = useState([]);
  const [medicineData, setMedicineData] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();

  console.log("state", pstate);
  console.log("pstate", state);

  // View patient data
  useEffect(() => {
    axios
      .get(`https://hospital-api.hisabdev.com/api/outdoor-pre-checkup/${id}`)
      .then((resp) => {
        setPState({ ...resp.data[0] });
        const {
          name,
          mobilenumber,
          sex,
          age,
          note,
          tests,
          doctorname,
          guardianname,
          guardiannumber,
          address,
          price,
          orpCode,
          type,
          group_tests,
          medicines,
          notes,
          operation 
          //     selectedPackage,
          //     packageAmount,
          // discount,
          // netAmount
        } = resp.data[0];
        setState((prevState) => ({
          ...prevState,
          name,
          mobilenumber,
          sex,
          age,
          note,
          doctorname,
          guardianname,
          guardiannumber,
          address,
          price,
          orpCode,
          type,
          notes,
          tests: tests || [{ testname: "", result: "", amount: 0 }],
          group_tests: group_tests || [
            { gptestname: "", gpresult: "", gpamount: 0 },
          ],
          medicines: medicines || [{ name: "", instruction: "", dosage: "" }],
          selectedPackage: "null",
          packageAmount: 0.0,
          discount: 0.0,
          netAmount: 0.0,
          operation 
        }));
      })
      .catch((error) => {
        console.error("Error fetching patient data:", error);
      });
  }, [id]);

  // const handleUpdateStatus = () => {
  //   const updatedStatus = {
  //     ...pstate,
  //     status: "checked",
  //   };

  //   axios
  //     .put(
  //       `https://hospital-api.hisabdev.com/api/registation/updateregistation/${id}`,
  //       updatedStatus
  //     )
  //     .then(() => {
  //       setPState(initialStatae);
  //       toast.success("Patient status updated successfully.");
  //     })
  //     .catch((err) => {
  //       console.error("Error updating patient status:", err);
  //       toast.error("Failed to update patient status.");
  //     });
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!state.mobilenumber) {
      toast.error("Please provide a value for the mobile number field.");
    } else {
      axios
        .post(
          "https://hospital-api.hisabdev.com/api/prescription/createoutdoorprescription",
          {
            ...state,
          }
        )
        .then(() => {
          console.log("sub3");
          setState(initialState);
          // handleUpdateStatus();
          toast.success("Prescription created successfully.");
          navigate("/checkup");
        })
        .catch((err) => {
          console.error("Error creating prescription:", err);
          toast.error("Failed to create prescription.");
        });

      // .then(() => {
      //   axios
      //   .put(`https://hospital-api.hisabdev.com/api/registation/updateregistation/${id}`, updatedStatus)
      // )}
      // .catch((err) => {
      //   console.error("Error updating patient status:", err);
      //   toast.error("Failed to update patient status.");
      // });
    }
  };

  // Fetch Test
  const loadTestData = async () => {
    try {
      const response = await axios.get("https://hospital-api.hisabdev.com/api/test");
      setTestData(response.data[0]);
    } catch (error) {
      console.error("Error loading test data:", error);
    }
  };

  const loadGroupTestData = async () => {
    try {
      const response = await axios.get(
        "https://hospital-api.hisabdev.com/api/test/grouptest"
      );
      setGroupTestData(response.data);
    } catch (error) {
      console.error("Error loading group test data:", error);
    }
  };

  // Fetch Medicine
  const loadMedicineData = async () => {
    try {
      const response = await axios.get("https://hospital-api.hisabdev.com/api/product");
      setMedicineData(response.data[0]);
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    loadMedicineData();
    loadTestData();
    loadGroupTestData();
  }, []);

  const handleAddMedicine = () => {
    setState((prevState) => ({
      ...prevState,
      medicines: [
        ...prevState.medicines,
        { name: "", instruction: "", dosage: "" },
      ],
    }));
  };

  const handleDeleteMedicine = (index) => {
    setState((prevState) => {
      const updatedMedicines = [...prevState.medicines];
      updatedMedicines.splice(index, 1);
      return { ...prevState, medicines: updatedMedicines };
    });
  };

  const handleTestChange = (index, event) => {
    const { name, value } = event.target;
    setState((prevState) => {
      const newTests = [...prevState.tests];
      newTests[index][name] = value;

      // Find the selected test data to get the amount
      const selectedTest = [...testData].find(
        (test) => test.testname === value
      );
      if (selectedTest) {
        newTests[index].amount = selectedTest.amount;
      }
      return { ...prevState, tests: newTests };
    });
  };

  const addTestField = () => {
    setState((prevState) => ({
      ...prevState,
      tests: [...prevState.tests, { testname: "", result: "", amount: 0 }],
    }));
  };

  const handleGpTestChange = (index, event) => {
    const { name, value } = event.target;
    setState((prevState) => {
      const newGpTests = [...prevState.group_tests];
      newGpTests[index][name] = value;

      const selectedGpTest = [...GroupTestData].find(
        (gtest) => gtest.name === value
      );
      if (selectedGpTest) {
        newGpTests[index].gpamount = selectedGpTest.PAmount;
      }
      return { ...prevState, group_tests: newGpTests };
    });
  };

  const addGpTestField = () => {
    setState((prevState) => ({
      ...prevState,
      group_tests: [
        ...prevState.group_tests,
        { gptestname: "", gpresult: "", gpamount: 0 },
      ],
    }));
  };

  const handleDeleteTest = (index) => {
    setState((prevState) => {
      const updatedTests = prevState.tests.filter((_, i) => i !== index);
      return { ...prevState, tests: updatedTests };
    });
  };

  const handleDeleteGpTest = (index) => {
    setState((prevState) => {
      const updatedGpTests = prevState.group_tests.filter(
        (_, i) => i !== index
      );
      return { ...prevState, group_tests: updatedGpTests };
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const {
    date,
    name,
    mobilenumber,
    sex,
    age,
    notes,
    note,
    tests,
    group_tests,
    medicines,
    operation 
  } = state;

  return (
    <div className="bg-sky-200 p-4 drop-shadow rounded-xl ml-4 w-11/12">
      <h2 className="text-xl font-semibold"> Prescription</h2>
      <Form onSubmit={handleSubmit}>
        <Row className="p-2">
          <Col lg={12} className="p-1">
            <div className="py-2">
              <h2 className="text-lg font-semibold">Patient Details:</h2>
              <Row>
                <Col lg={3} md={3} sm={12} xs={12}>
                  <Form.Group>
                    <Form.Label className="block text-gray-700 font-medium">
                      Name
                    </Form.Label>
                    <Input
                      placeholder="Enter Patiant Name"
                      type="text"
                      name="name"
                      value={name || ""}
                      readOnly
                    />
                  </Form.Group>
                </Col>
                <Col lg={2} md={3} sm={6} xs={6}>
                  <Form.Group>
                    <Form.Label className="block text-gray-700 font-medium">
                      Gender
                    </Form.Label>
                    <Form.Control
                      placeholder="Select a Sex"
                      name="sex"
                      value={sex || ""}
                      readOnly
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col lg={2} md={3} sm={6} xs={6}>
                  <Form.Group>
                    <Form.Label className="block text-gray-700 font-medium">
                      Age
                    </Form.Label>
                    <Form.Control
                      placeholder="age"
                      type="text"
                      id="age"
                      name="age"
                      value={age || ""}
                      readOnly
                    />
                  </Form.Group>
                </Col>
                <Col lg={3} md={3} sm={12} xs={12}>
                  <Form.Group>
                    <Form.Label className="block text-gray-700 font-medium">
                      Mobile No.
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="mobilenumber"
                      value={mobilenumber || ""}
                      readOnly
                    />
                  </Form.Group>
                </Col>
                <Col lg={2} md={3} sm={12} xs={12}>
                  <Form.Group>
                    <Form.Label className="block text-gray-700 font-medium">
                      Date
                    </Form.Label>
                    <Form.Control
                      placeholder="age"
                      type="date"
                      id="age"
                      name="date"
                      value={date || ""}
                      // value={currentDate.toISOString().split("T")[0]}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
                <Col lg={12}>
                  <Form.Group>
                    <Form.Label>Pre Checked Details</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={6}
                      name="notes"
                      value={notes || ""}
                      onChange={handleInputChange}
                      readOnly
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={3} md={6} sm={12}>
                  <Form.Group controlId="testsAssigned">
                    <Form.Label className="font-semibold">Tests</Form.Label>
                    {tests.map((test, index) => (
                      <div key={index}>
                        <Row className="my-1">
                          <Col>
                            <Form.Control
                              as="select"
                              name="testname"
                              value={test.testname}
                              onChange={(e) => handleTestChange(index, e)}
                            >
                              <option value="">Select Test</option>
                              {testData.map((option) => (
                                <option
                                  key={option.testname}
                                  value={option.testname}
                                >
                                  {option.testname}
                                </option>
                              ))}
                            </Form.Control>
                          </Col>
                          {index !== 0 ? (
                            <Col lg={2}>
                              <Button
                                variant="danger"
                                onClick={() => handleDeleteTest(index)}
                              >
                                <RxCross2 />
                              </Button>
                            </Col>
                          ) : (
                            <Col lg={2}></Col>
                          )}
                        </Row>
                      </div>
                    ))}
                  </Form.Group>
                  <Button
                    className="w-full my-2"
                    variant="outline-primary"
                    onClick={addTestField}
                  >
                    Add Test
                  </Button>
                </Col>
                <Col lg={3} md={6} sm={12}>
                  <Form.Group>
                    <Form.Label className="font-semibold">
                      Group Tests
                    </Form.Label>
                    {group_tests.map((gtest, index) => (
                      <div key={index}>
                        <Row className="my-1">
                          <Col>
                            <Form.Control
                              as="select"
                              name="gptestname"
                              value={gtest.gptestname}
                              onChange={(e) => handleGpTestChange(index, e)}
                            >
                              <option value="">Select Test</option>
                              {GroupTestData.map((group) => (
                                <option key={group.name} value={group.name}>
                                  {group.name}
                                </option>
                              ))}
                            </Form.Control>
                          </Col>
                          {index !== 0 ? (
                            <Col lg={2}>
                              <Button
                                variant="danger"
                                onClick={() => handleDeleteGpTest(index)}
                              >
                                <RxCross2 />
                              </Button>
                            </Col>
                          ) : (
                            <Col lg={2}></Col>
                          )}
                        </Row>
                      </div>
                    ))}
                  </Form.Group>
                  <Button
                    className="w-full my-2"
                    variant="outline-primary"
                    onClick={addGpTestField}
                  >
                    Add Group Test
                  </Button>
                </Col>
                <Col lg={6} md={12} sm={12}>
                  <Form.Group>
                    <Form.Label className="font-semibold">
                      Prescribed Medicines
                    </Form.Label>
                    {medicines.map((medicine, index) => (
                      <div
                        key={index}
                        className="border p-1 rounded flex flex-row gap-1 mb-1"
                      >
                        <Form.Control
                          as="select"
                          value={medicine.name}
                          onChange={(e) => {
                            const updatedMedicines = [...medicines];
                            updatedMedicines[index].name = e.target.value;
                            setState((prevState) => ({
                              ...prevState,
                              medicines: updatedMedicines,
                            }));
                          }}
                        >
                          <option value="">Select Medicine</option>
                          {medicineData.map((medi) => (
                            <option
                              key={medi.Description}
                              value={medi.Description}
                            >
                              {medi.Description}
                            </option>
                          ))}
                        </Form.Control>
                        <Form.Control
                          type="text"
                          placeholder="Instruction"
                          value={medicine.instruction}
                          onChange={(e) => {
                            const updatedMedicines = [...medicines];
                            updatedMedicines[index].instruction =
                              e.target.value;
                            setState((prevState) => ({
                              ...prevState,
                              medicines: updatedMedicines,
                            }));
                          }}
                        />
                        <Form.Control
                          type="text"
                          placeholder="Dosage"
                          value={medicine.dosage}
                          onChange={(e) => {
                            const updatedMedicines = [...medicines];
                            updatedMedicines[index].dosage = e.target.value;
                            setState((prevState) => ({
                              ...prevState,
                              medicines: updatedMedicines,
                            }));
                          }}
                        />
                        <Button
                          disabled={index === 0}
                          variant="danger"
                          onClick={() => handleDeleteMedicine(index)}
                        >
                          <RxCross2 />
                        </Button>
                      </div>
                    ))}
                  </Form.Group>
                  <Button
                    className="w-full"
                    variant="outline-primary"
                    onClick={handleAddMedicine}
                  >
                    Add Medicine
                  </Button>
                </Col>
              </Row>
              <Col lg={12}>
                <Form.Group>
                  <Form.Label>Note</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={6}
                    name="note"
                    value={note}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
              <Col lg={3} md={3} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label className="block text-gray-700 font-medium">
                    Operation
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="operation"
                    value={operation || "No"}
                    onChange={handleInputChange}
                  >
                    <option value="No">No</option>
                    <option value="Yes">Yes</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </div>
          </Col>
        </Row>
        <div className="flex justify-content-between my-4">
          <Button
            variant="danger"
            onClick={() => navigate("/checkup")}
            className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 focus:outline-none focus:bg-red-600"
          >
            Back
          </Button>
          <Button
            onClick={() => {
              console.log("hello");
            }}
            type="submit"
            className="px-4 py-2"
          >
            Create
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default Prescription;
