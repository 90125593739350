import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate, useParams } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
import { useAuth } from "../context/AuthContext";


const initialState = {
  orpCode: "",
  date: new Date().toISOString().split("T")[0],
  image: "",
  name: "",
  address: "",
  mobilenumber: "",
  sex: "",
  age: "",
  guardiannumber: "",
  guardianname: "",
  doctorname: "",
  doctordesignation: "",
  time: new Date().toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  }),
  status: "checked",
  doctorCheckupStatus: "no",
  tests: [{ testname: "", result: "", amount: 0 }],
  notes: "",
  createdBy:"",
};

function UpdatePreCheckup() {
  const { username } = useAuth();
  const [doctorList, setDoctor] = useState([]);
  const [testData, setTestData] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState({});
  const [state, setState] = useState({ ...initialState, createdBy: username });
  const {
    orpCode,
    date,
    address,
    name,
    image,
    mobilenumber,
    sex,
    age,
    guardiannumber,
    guardianname,
    doctorname,
    doctordesignation,
    time,
    type,
    price,
    amount,
    status,
    doctorCheckupStatus,
    tests,
    notes,
    createdBy
  } = state;
  const { id } = useParams();
  const navigate = useNavigate();

  // Fetch Test
  const loadTestData = async () => {
    try {
      const response = await axios.get("https://hospital-api.hisabdev.com/api/test");
      setTestData(response.data[0]);
    } catch (error) {
      console.error("Error loading test data:", error);
    }
  };

  useEffect(() => {
    loadTestData();
  }, []);

  useEffect(() => {
    axios
      .get(`https://hospital-api.hisabdev.com/api/outdoreregistation/${id}`)
      .then((resp) => setState({ ...resp.data[0] }));
  }, [id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!name) {
      toast.error("Please provide value into * input field ");
    } else {
      axios
        .post(`https://hospital-api.hisabdev.com/api/outdoor-pre-checkup/saveoutdoorprecheckup`, {
          orpCode,
          date,
          address,
          name,
          image,
          mobilenumber,
          sex,
          age,
          guardiannumber,
          guardianname,
          doctorname,
          doctordesignation,
          time,
          type,
          price,
          amount,
          status: "checked",
          doctorCheckupStatus: "no",
          tests,
          notes,
          createdBy
        })
        .then(() => {
          setState({ ...initialState, createdBy: username })
          toast.success("Pre-Checkup Saved Successfully");
        })
        .catch((err) => toast.error(err.response.data));
      setTimeout(() => {
        navigate("/pre-checkup");
      }, 500);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "doctorname") {
      const doctor = doctorList.find((doc) => doc.doctorname === value);
      setSelectedDoctor(doctor);
      setState({
        ...state,
        [name]: value,
        doctordesignation: doctor ? doctor.designation : "",
      });
    } else if (name === "type") {
      let newPrice = "";
      if (value === "Free") {
        newPrice = "0.00";
      } else if (selectedDoctor) {
        switch (value) {
          case "Special":
            newPrice = selectedDoctor.specialFees;
            break;
          case "General":
            newPrice = selectedDoctor.generalFees;
            break;
          case "OnCall":
            newPrice = selectedDoctor.onCallFees;
            break;
          default:
            newPrice = "";
        }
      }
      setState({ ...state, [name]: value, price: newPrice });
    } else {
      setState({ ...state, [name]: value });
    }
  };

  const handleTestChange = (index, event) => {
    const { name, value } = event.target;
    setState((prevState) => {
      const newTests = Array.isArray(prevState.tests)
        ? [...prevState.tests]
        : [];
      newTests[index] = { ...newTests[index], [name]: value };

      // Find the selected test data to get the amount
      const selectedTest = testData?.find((test) => test.testname === value);
      if (selectedTest) {
        newTests[index].amount = selectedTest.amount;
      }

      // Calculate the total amount
      const totalAmount = newTests.reduce(
        (sum, test) => sum + parseFloat(test.amount || 0),
        0
      );

      return { ...prevState, tests: newTests, amount: totalAmount.toFixed(2) };
    });
  };

  const addTestField = () => {
    setState((prevState) => ({
      ...prevState,
      tests: Array.isArray(prevState.tests)
        ? [...prevState.tests, { testname: "", result: "", amount: 0 }]
        : [{ testname: "", result: "", amount: 0 }],
    }));
  };

  const handleDeleteTest = (index) => {
    setState((prevState) => {
      const updatedTests = Array.isArray(prevState.tests)
        ? prevState.tests.filter((_, i) => i !== index)
        : [];
      // Calculate the total amount
      const totalAmount = updatedTests.reduce(
        (sum, test) => sum + parseFloat(test.amount || 0),
        0
      );

      return { ...prevState, tests: updatedTests, amount: totalAmount.toFixed(2) };
    });
  };
  return (
    <div className="container p-2">
      <Form onSubmit={handleSubmit}>
        <Row className="p-2">
          <Col lg={12} className="p-1">
            <div className="bg-sky-200 rounded p-2 drop-shadow">
              <div className="py-2">
                {/* <h2 className="text-lg font-semibold">Patient Details:</h2> */}
                <Row>
                  <Col lg={2} md={2} sm={6}>
                    <Form.Group>
                      <Form.Label className="block text-gray-700 font-medium">
                        Patient ID
                      </Form.Label>
                      <Form.Control
                        readOnly
                        type="text"
                        name="orpCode"
                        value={orpCode || ""}
                      />
                    </Form.Group>
                  </Col>
                  {/* <Col lg={2} md={2} sm={6}>
                    <Form.Group>
                      <Form.Label className="block text-gray-700 font-medium">
                        Date
                      </Form.Label>
                      <Form.Control
                        readOnly
                        type="date"
                        name="date"
                        value={date}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={2} md={2} sm={6}>
                    <Form.Group>
                      <Form.Label className="block text-gray-700 font-medium">
                        Time
                      </Form.Label>
                      <Form.Control
                        readOnly
                        type="text"
                        name="time"
                        value={time}
                      />
                    </Form.Group>
                  </Col> */}
                  <Col lg={3} md={3} sm={6} xs={6}>
                    <Form.Group>
                      <Form.Label className="block text-gray-700 font-medium">
                        Name
                      </Form.Label>
                      <Form.Control
                        readOnly
                        type="text"
                        name="name"
                        value={name || ""}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={3} md={3} sm={6} xs={6}>
                    <Form.Group>
                      <Form.Label className="block text-gray-700 font-medium">
                        Mobile Number
                      </Form.Label>
                      <Form.Control
                        readOnly
                        type="text"
                        name="mobilenumber"
                        value={mobilenumber || ""}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={4} md={4} sm={6} xs={6}>
                    <Form.Group>
                      <Form.Label className="block text-gray-700 font-medium">
                        Address
                      </Form.Label>
                      <Form.Control
                        readOnly
                        type="text"
                        name="address"
                        value={address || ""}
                      />
                    </Form.Group>
                  </Col>

                  <Col lg={1} md={1} sm={6} xs={6}>
                    <Form.Group>
                      <Form.Label className="block text-gray-700 font-medium">
                        Gender
                      </Form.Label>
                      <Form.Control
                        readOnly
                        type="text"
                        name="sex"
                        value={sex || ""}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={1} md={1} sm={6} xs={6}>
                    <Form.Group>
                      <Form.Label className="block text-gray-700 font-medium">
                        Age
                      </Form.Label>
                      <Form.Control
                        readOnly
                        type="text"
                        name="age"
                        value={age || ""}
                      />
                    </Form.Group>
                  </Col>
                  {/* <Col lg={3} md={3} sm={6} xs={6}>
                    <Form.Group>
                      <Form.Label className="block text-gray-700 font-medium">
                        Guardian Name
                      </Form.Label>
                      <Form.Control
                        readOnly
                        type="text"
                        name="guardianname"
                        value={guardianname || ""}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={3} md={3} sm={6} xs={6}>
                    <Form.Group>
                      <Form.Label className="block text-gray-700 font-medium">
                        Guardian Mobile No.
                      </Form.Label>
                      <Form.Control
                        readOnly
                        type="text"
                        name="guardiannumber"
                        value={guardiannumber || ""}
                      />
                    </Form.Group>
                  </Col> */}
                  <Col lg={3} md={3} sm={12}>
                    <Form.Group>
                      <Form.Label className="block text-gray-700 font-medium">
                        Doctor
                      </Form.Label>
                      <Form.Control
                        readOnly
                        type="text"
                        name="doctorname"
                        value={doctorname || ""}
                      />
                    </Form.Group>
                  </Col>
                  {/* <Col lg={2} md={2} sm={12}>
                    <Form.Group>
                      <Form.Label className="block text-gray-700 font-medium">
                        Type
                      </Form.Label>
                      <Form.Control
                        readOnly
                        type="text"
                        name="type"
                        value={type || ""}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={2} md={2} sm={12}>
                    <Form.Group>
                      <Form.Label className="block text-gray-700 font-medium">
                        Price
                      </Form.Label>
                      <Form.Control
                        readOnly
                        type="text"
                        name="price"
                        value={price || ""}
                      />
                    </Form.Group>
                  </Col> */}
                  <Col lg={2} md={2} sm={12}>
                    <Form.Group>
                      <Form.Label className="block text-gray-700 font-medium">
                        Amount
                      </Form.Label>
                      <Form.Control
                        // readOnly
                        type="text"
                        name="amount"
                        value={amount || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="p-2">
          <Col lg={3} md={6} sm={12}>
            <Form.Group controlId="testsAssigned">
              <Form.Label className="font-semibold">Tests</Form.Label>
              {tests?.map((test, index) => (
                <div key={index}>
                  <Row className="my-1">
                    <Col>
                      <Form.Control
                        as="select"
                        name="testname"
                        value={test.testname}
                        onChange={(e) => handleTestChange(index, e)}
                      >
                        <option value="">Select Test</option>
                        {testData.map((option) => (
                          <option key={option.testname} value={option.testname}>
                            {option.testname}
                          </option>
                        ))}
                      </Form.Control>
                      {/* <Form.Group as={Col} controlId={`amount-${index}`}>
                        <Form.Label>Amount</Form.Label>
                        <Form.Control
                          type="number"
                          name="amount"
                          value={test.amount || 0}
                          onChange={(e) => handleTestChange(index, e)}
                          placeholder="Enter the amount"
                          readOnly
                        />
                      </Form.Group> */}
                    </Col>
                    {index !== 0 ? (
                      <Col lg={2}>
                        <Button
                          variant="danger"
                          onClick={() => handleDeleteTest(index)}
                        >
                          <RxCross2 />
                        </Button>
                      </Col>
                    ) : (
                      <Col lg={2}></Col>
                    )}
                  </Row>
                </div>
              ))}
            </Form.Group>
            <Button
              className="w-full my-2"
              variant="outline-primary"
              onClick={addTestField}
            >
              Add Test
            </Button>
          </Col>
          <Col lg={12} className="p-1">
            <div className="bg-sky-200 rounded p-2 drop-shadow">
              <div className="py-2">
                <Form.Group>
                  <Form.Label className="block text-gray-700 font-medium">
                    Notes
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="notes"
                    value={notes}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </div>
            </div>
          </Col>
        </Row>
        <div className="flex justify-content-between my-4">
          <Button
            variant="danger"
            onClick={() => navigate("/pre-checkup")}
            className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 focus:outline-none focus:bg-red-600"
          >
            Cancel
          </Button>
          <Button variant="success" type="submit" className="px-4 py-2">
            Save
          </Button>
        </div>
        {/* </Form> */}
      </Form>
    </div>
  );
}

export default UpdatePreCheckup;
